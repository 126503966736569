const logged = (state = localStorage.getItem('user'), action) => {
  switch (action.type) {
    case 'LOG_IN':
      return true
    case 'LOG_OUT':
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      localStorage.removeItem('permisos')
      return false
    default:
      return state
  }
}
export default logged
