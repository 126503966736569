import React, { useEffect, useState } from 'react'
import { Col, ControlLabel, Form, FormControl, FormGroup, Grid, Row, } from 'react-bootstrap'
import Card from 'components/Card/Card.jsx'
import Button from 'components/CustomButton/CustomButton.jsx'
import Select from 'react-select'
import Mapa from './Map2'
import { FileUpload, Get, ListBrands, Submit } from '../../services/localServices'
import Image from 'react-bootstrap/lib/Image'
import swal from 'sweetalert'
import { listRubros } from '../../services/rubroServices'

const LocalForm = () => {
  const permisos = JSON.parse(localStorage.getItem('permisos'))

  const [path, setPath] = useState([{ lat: -25.283810, lng: -57.56645 }, { lat: -25.274927, lng: -57.544122 }])
  const [state, setState] = useState({
    radio: '1',
    radioVariant: '1',
    local: {
      name: '',
      image: '',
      selected_brand: null,
      url_image: '',
      selected_rubros: null,
    },
    rubros: [
      {
        value: '',
        label: ' Multiple Options',
        isDisabled: true
      },
    ],
    brands: [],

    count: 0,
    select: null
  })
  useEffect(() => {
      (async () => {
        if (state.count < 1) {
          state.count++
          listRubros()
            .then((response) => response.json())
            .then(data => {
              state.rubros = data.data.map(function (e) {
                return { value: e._id, label: e.name }
              })
            })
            .then((data) => {
              ListBrands()
                .then((response) => response.json())
                .then(data => {
                  let newBrands = []
                  console.log(data)
                  const brands = data.data.forEach(function (e) {
                    if (permisos.permissions === 'superadmin') {
                      newBrands.push({ value: e._id, label: e.name })
                    } else {
                      if (e._id === permisos.brand) {
                        newBrands.push({ value: e._id, label: e.name })
                      }
                    }
                  })
                  state.brands = [{
                    value: '',
                    label: '',
                    isDisabled: true
                  }, ...newBrands]
                })
                .then(function (e) {
                  const id = window.location.search.substring(1)
                  if (id !== '') {
                    try {
                      Get(id)
                        .then(e => (e.status === 200) ? e.json() : 'error')
                        .then(data => {
                          console.log('asdasdasd')
                          if (data !== 'error') {
                            state.local.id = data.result._id
                            state.local.name = data.result.name
                            state.local.selected_brand = data.result.selected_brand
                            state.local.selected_rubros = data.result.selected_rubros
                            if (data.result.url_image) {state.local.url_image = data.result.url_image}
                            const path = data.result.path
                            setPath(
                              path
                            )
                            const local = state.local
                            console.log('local')
                            console.log(local)
                            setState({
                              ...state,
                              ...state
                            })
                            console.log('asd')
                          }
                        })
                        .catch(e => console.warn(e))
                    } catch (e) {
                      console.warn(e)
                    }
                  }else{
                    setState({
                      ...state,
                      ...state
                    })
                  }
                })

            })
        }
      })()
    }, []
  )

  const handleInput = (e) => {
    state.local.name = e.target.value
    const local = state.local
    setState({
      ...state,
      local
    })
  }
  const handleSubmit = e => {
    e.preventDefault()
    let formData = new FormData()
    formData.append('image', state.local.image)
    if (state.local.image !== '') {
      FileUpload(formData)
        .then(e => (e.status === 201) ? e.json() : 'Error')
        .then(data => {
          if (data !== 'Error') {
            state.local.url_image = data.image
            const local = state.local
            setState({
              ...state,
              local
            })
            saveInfo()
          }
        })
        .catch(e => console.warn(e))
    } else {
      saveInfo()
    }

  }
  const saveInfo = () => {
    state.local.path = path
    Submit(state.local)
      .then(e => {
          if (e.status === 200)
            return swal('Guardado', '', 'success').then(r => window.location.href = '/admin/locales')
          else
            return swal('Ha Ocurrido un error durante el guardado', '', 'error').then(r => console.log(r))
        }
      )
      .catch(e => {
        return swal('Ha ocurrido un error al comunicarse con el servidor', '', 'warning').then(r => console.log(r))
      })
  }
  const handleSelectChange = value => {
    state.local.selected_brand = value
    const local = state.local
    setState({
      ...state,
      local
    })
    console.log('handleSelectChange')
    console.log(state)
  }

  const handleSelectRubroChange = value => {
    state.local.selected_rubros = value
    const local = state.local
    setState({
      ...state,
      local
    })
    console.log('handleSelectChange')
    console.log(state)
  }

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      state.local.image = e.target.files[0]
      const local = state.local
      setState({
        ...state,
        local
      })
    }
  }
  console.log('state')
  console.log(state)
  return (
    <div className="main-content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={<legend>Local</legend>}
              content={
                <Form onSubmit={handleSubmit} horizontal>
                  <fieldset>
                    <FormGroup>
                      <ControlLabel className="col-sm-2">
                        Nombre
                      </ControlLabel>
                      <Col sm={10}>
                        <FormControl type="text" defaultValue={state.local.name} onChange={handleInput}/>
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <ControlLabel className="col-sm-2">
                        Marca
                      </ControlLabel>
                      <Col sm={10}>
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          placeholder="Marca"
                          name="select"
                          closeMenuOnSelect={true}
                          value={state.local.selected_brand}
                          onChange={handleSelectChange}
                          options={
                            state.brands
                          }
                        />
                      </Col>
                    </FormGroup>

                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <ControlLabel className="col-sm-2">
                        Rubros
                      </ControlLabel>
                      <Col sm={10}>
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          placeholder="Elegi tus Rubros"
                          name="multipleSelect"
                          closeMenuOnSelect={false}
                          isMulti
                          value={state.local.selected_rubros}
                          onChange={handleSelectRubroChange}
                          options={
                            state.rubros
                          }
                        />
                      </Col>
                    </FormGroup>

                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <ControlLabel className="col-sm-2">
                        Nueva Imagen
                      </ControlLabel>
                      <Col sm={10}>
                        <FormControl type="File" name='image' defaultValue={state.local.image}
                                     onChange={handleImageChange}/>
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset
                    hidden={(state.local.url_image === '' || state.local.url_image === undefined)}
                  >
                    <FormGroup>
                      <ControlLabel className="col-sm-2">
                        Imagen
                      </ControlLabel>
                      <Col sm={10} xs={10} md={10}>
                        <Image src={state.local.url_image} thumbnail/>
                      </Col>
                    </FormGroup>
                  </fieldset>

                  <br/>
                  <Mapa path={path} setPath={setPath}/>
                  <br/>
                  <FormGroup>
                    <Col md={10} mdOffset={4}>
                      <Button bsStyle="warning" fill wd onClick={e => window.location.href = '/admin/locales'}>
                        Volver
                      </Button>
                      <Button bsStyle="default" fill wd type={'submit'}>
                        Guardar
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default LocalForm
