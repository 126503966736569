import React, { Component } from 'react'
import { Col, ControlLabel, Form, FormControl, FormGroup, Grid, Row } from 'react-bootstrap'

import Card from 'components/Card/Card.jsx'
import Button from 'components/CustomButton/CustomButton.jsx'
import Select from 'react-select'
import { listRubros } from '../../services/rubroServices'
import { FileUpload, Get, Submit } from '../../services/brandServices'
import swal from 'sweetalert'
import Image from 'react-bootstrap/lib/Image'

class BrandForm extends Component {
  constructor (props) {
    super(props)
    /*TODO get rubros  /if update get data*/
    this.state = {
      radio: '1',
      radioVariant: '1',
      brand: {
        name: '',
        url: '',
        image: '',
        url_image: '',
        selected_rubros: null,
      },
      rubros: [
        {
          value: '',
          label: ' Multiple Options',
          isDisabled: true
        },
      ],
    }
  }

  handleInput = event => {
    const target = event.target
    this.state.brand[target.name] = target.value
    const brand = this.state.brand
    this.setState({
      brand
    })
    console.log(this.state)
  }

  handleSubmit = e => {
    e.preventDefault()
    /*TODO Submit*/

    let formData = new FormData()
    formData.append('image', this.state.brand.image)
    console.log(this.state.brand)
    if (this.state.brand.image !== '') {
      FileUpload(formData)
        .then(e => (e.status === 201) ? e.json() : 'Error')
        .then(data => {
          if (data !== 'Error') {
            this.state.brand.url_image = data.image
            const brand = this.state.brand
            this.setState({
              brand
            })
            this.saveInfo()
          }
        })
        .catch(e => console.log(e))
    }else{
      this.saveInfo()
    }

  }

  saveInfo () {
    Submit(this.state.brand)
      .then(e => {
          if (e.status === 200)
            return swal('Guardado', '', 'success').then(r => window.location.href = '/admin/marcas')
          else
            return swal('Ha Ocurrido un error durante el guardado', '', 'error').then(r => console.log(r))
        }
      )
      .catch(e => {
        return swal('Ha ocurrido un error al comunicarse con el servidor', '', 'warning').then(r => console.log(r))
      })
  }

  handleSelectChange = value => {
    this.state.brand.selected_rubros = value
    const brand = this.state.brand
    this.setState({ brand })
    console.log(this.state)
  }

  handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      this.state.brand.image = e.target.files[0]
      // this.state.brand.img = URL.createObjectURL(e.target.files[0])
      const brand = this.state.brand
      this.setState({
        brand
      })
    }
    console.log(this.state)

    // console.log(e.target.files)
    // this.setState({
    //   [e.target.name]: e.target.value
    // })
  }

  generateObject (data = false) {
    if (data === false) {
      return {
        radio: '1',
        radioVariant: '1',
        brand: {
          id: '',
          name: '',
          image: '',
          url: '',
          selected_rubros: null,
          multipleSelect: [{ value: '3', label: '3' }]
        },
        rubros: [
          {
            value: '',
            label: ' Multiple Options',
            isDisabled: true
          },
          { value: '1', label: '1' },
          { value: '2', label: '2' },
          { value: '3', label: '3' },
        ],
        multipleSelect: null
      }
    }

  }

  componentDidMount () {
    listRubros()
      .then((response) => response.json())
      .then(data => {
        console.log(data.data)
        const newRubros = data.data.map(function (e) {
          console.log(e)
          return { value: e._id, label: e.name }
        })
        console.log(newRubros)
        this.state.rubros = newRubros
        const rubros = this.state.rubros
        console.log(this.state.rubros)
        this.setState({
          rubros
        })
        console.log(this.state)
      })
    /*asdasdasdasddddddddddddddddd*/

    const id = window.location.search.substring(1)
    if (id !== '') {
      try {
        Get(id)
          .then(e => (e.status === 200) ? e.json() : 'error')
          .then(data => {
            if (data !== 'error') {
              console.log(data)
              this.state.brand.id = data.result._id
              this.state.brand.name = data.result.name
              this.state.brand.url_image = data.result.url_image
              this.state.brand.url = data.result.url
              this.state.brand.selected_rubros = data.result.selected_rubros
              const newState = this.state
              this.setState({
                newState
              })
              console.log(this.state)
            }
          })
          .catch(e => console.log(e))
      } catch (e) {
        console.log(e)
      }
    }

  }

  render () {
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title={<legend>Marca</legend>}
                content={
                  <Form onSubmit={this.handleSubmit} horizontal>
                    <fieldset>
                      <FormGroup>
                        <ControlLabel className="col-sm-2">
                          Nombre
                        </ControlLabel>
                        <Col sm={10}>
                          <FormControl type="text" name='name' defaultValue={this.state.brand.name}
                                       onChange={this.handleInput}/>
                        </Col>
                      </FormGroup>
                    </fieldset>
                    <fieldset>
                      <FormGroup>
                        <ControlLabel className="col-sm-2">
                          URL
                        </ControlLabel>
                        <Col sm={10}>
                          <FormControl type="text" name='url' defaultValue={this.state.brand.url}
                                       onChange={this.handleInput}/>
                        </Col>
                      </FormGroup>
                    </fieldset>
                    <fieldset>
                      <FormGroup>
                        <ControlLabel className="col-sm-2">
                          Rubros
                        </ControlLabel>
                        <Col sm={10}>
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            placeholder="Elegi tus Rubros"
                            name="multipleSelect"
                            closeMenuOnSelect={false}
                            isMulti
                            value={this.state.brand.selected_rubros}
                            onChange={this.handleSelectChange}
                            options={
                              this.state.rubros
                            }
                          />
                        </Col>
                      </FormGroup>
                    </fieldset>
                    <fieldset>
                      <FormGroup>
                        <ControlLabel className="col-sm-2">
                          Nueva Imagen
                        </ControlLabel>
                        <Col sm={10}>
                          <FormControl type="File" name='image' defaultValue={this.state.brand.image}
                                       onChange={this.handleImageChange}/>
                        </Col>
                      </FormGroup>
                    </fieldset>
                    <fieldset
                      hidden={(this.state.brand.url_image === '')}
                    >
                      <FormGroup>
                        <ControlLabel className="col-sm-2">
                          Imagen
                        </ControlLabel>
                        <Col sm={10} xs={10} md={10}>
                          <Image src={this.state.brand.url_image} thumbnail/>
                        </Col>
                      </FormGroup>
                    </fieldset>
                    <FormGroup>
                      <Col md={9} mdOffset={5}>
                        <Button bsStyle="warning" fill wd onClick={e => window.location.href = '/admin/marcas'}>
                          Volver
                        </Button>
                        <Button bsStyle="default" fill wd type={'submit'}>
                          Guardar
                        </Button>
                      </Col>
                    </FormGroup>
                  </Form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default BrandForm
