/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import RegisterForm from "views/Pages/RegisterForm.js";
import LocalesTables from './views/Tables/LocalesTables'
import LocalForm from './views/Forms/LocalForm'

const routes2 = [
  {
    collapse: false,
    // state: "openTables",
    icon: 'pe-7s-news-paper',
    path: '/locales',
    layout: '/admin',
    name: 'locales',
    mini: 'RT',
    component: LocalesTables
  }, {
    collapse: false,
    // state: "openTables",
    icon: 'pe-7s-news-paper',
    path: '/local-form',
    layout: '/admin',
    name: 'local',
    mini: 'RT',
    redirect: true,
    component: LocalForm
  }]
export default routes2
