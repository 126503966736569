import React from 'react'

import { Col, ControlLabel, FormControl, FormGroup, Grid, Row } from 'react-bootstrap'

import Button from 'components/CustomButton/CustomButton.jsx'
import Card from 'components/Card/Card.jsx'

const LoginForm = ({ handleSubmit, handleInputChange, form, cardHidden }) => {
  if (cardHidden.cardHidden) {
    return (
      <>
        <Grid>
          <Row>
            <Col md={4} sm={6} mdOffset={4} smOffset={3}>
              <form onSubmit={handleSubmit}>
                <Card
                  hidden='false'
                  textCenter
                  title="Login"
                  content={
                    <div>
                      <FormGroup>
                        <ControlLabel>Name</ControlLabel>
                        <FormControl placeholder="Email" type="texts" name='email' defaultValue={form.email}
                                     onChange={handleInputChange}/>
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel>Password</ControlLabel>
                        <FormControl onChange={handleInputChange} placeholder="Password" name='password' type="password"
                                     autoComplete="off" defaultValue={form.password}/>
                      </FormGroup>
                    </div>
                  }
                  legend={
                    <Button bsStyle="info" fill wd type="submit">
                      Login
                    </Button>
                  }
                  ftTextCenter
                />
              </form>
            </Col>
          </Row>
        </Grid>
      </>
    )
  }
  return (
    <>
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form onSubmit={handleSubmit}>
              <Card
                textCenter
                title="Login"
                content={
                  <div>
                    <FormGroup>
                      <ControlLabel>Email</ControlLabel>
                      <FormControl placeholder="Email" type="text" name='email' defaultValue={form.email}
                                   onChange={handleInputChange}/>
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Password</ControlLabel>
                      <FormControl onChange={handleInputChange} placeholder="Default:12345678" name='password' type="password"
                                   autoComplete="off" defaultValue={form.password}/>
                    </FormGroup>
                  </div>
                }
                legend={
                  <Button bsStyle="info" fill wd type="submit">
                    Login
                  </Button>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    </>
  )
}

export default LoginForm
