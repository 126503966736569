import React, { useRef, useCallback } from 'react'
import { LoadScript, GoogleMap, Polygon } from '@react-google-maps/api'

import './css/styles.css'

function Mapa ({ path, setPath }) {
  const polygonRef = useRef(null)
  const listenersRef = useRef([])

  // Call setPath with new edited path
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() }
        })
      setPath(nextPath)
    }
  }, [setPath])

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    polygon => {
      polygonRef.current = polygon
      const path = polygon.getPath()
      listenersRef.current.push(
        path.addListener('set_at', onEdit),
        path.addListener('insert_at', onEdit),
        path.addListener('remove_at', onEdit)
      )
    },
    [onEdit]
  )

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove())
    polygonRef.current = null
  }, [])

  const onClick = (e) => {
    // const asd = [{ lat: -25.283810, lng: -57.56645 }]
    // setPath({
    //   ...path,
    //   asd
    // })
    // setState({
    //   ...state,
    //   state
    //   // [e.target.name]: e.target.value,
    // })
  }

  return (
    <div className="App">
      <LoadScript
        id="script-loader"
        googleMapsApiKey="AIzaSyCjyZq8RSfrImlIfnf5Rhbq-IXRqA5Dh7s"
        language="en"
        region="us"
      >
        <GoogleMap
          mapContainerClassName="App-map"
          center={{ lat: -25.283810, lng: -57.56645 }}
          zoom={14}
          version="weekly"
          on
          onClick={onClick}
        >
          <Polygon
            // Make the Polygon editable / draggable
            editable
            draggable
            path={path}
            onMouseUp={onEdit}
            onDragEnd={onEdit}
            onLoad={onLoad}
            onUnmount={onUnmount}
          />
        </GoogleMap>
      </LoadScript>
    </div>
  )
}

export default Mapa
