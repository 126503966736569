/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import App from './App'
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/sass/light-bootstrap-dashboard-pro-react.scss?v=1.2.0";
import "assets/css/demo.css";
import "assets/css/pe-icon-7-stroke.css";

import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";


import { Provider } from 'react-redux'
import allReducers from '../src/reducers/allReducers'

import { createStore, applyMiddleware } from 'redux'
import reduxThunk from 'redux-thunk'
const rootReducer = allReducers

const myStore = createStore(rootReducer, {},applyMiddleware(reduxThunk))

myStore.subscribe(() => {
  console.log(myStore.getState())
})

ReactDOM.render(
  <Provider store={myStore}>
    <App/>
  </Provider>,
  document.getElementById("root")
);
