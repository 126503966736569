/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { Component } from 'react'
// react component for creating dynamic tables
import ReactTable from 'react-table'
import { Col, Grid, Row } from 'react-bootstrap'

import Card from 'components/Card/Card.jsx'
import Button from 'components/CustomButton/CustomButton.jsx'
import { DeleteRubro, listRubros } from '../../services/rubroServices'
import swal from 'sweetalert'

let dataTable = [{ name: 'asd' }]

class RubrosTables extends Component {
  constructor (props) {
    super(props)
    this.startState(dataTable)
  }

  startState (data) {
    this.state = this.generateObject(data)
  }

  updateState (data) {
    this.setState(this.generateObject(data))
  }

  delete (e, _id, key) {
    console.log(e, _id, key)
    DeleteRubro({ _id: _id })
      .then((response) => {
          if (response.status === 200) {
            return response.json()
          } else {
            swal('Error', 'Si el error persiste contactese con soporte', 'error').then(r => console.log(r))
            return 'error'
          }
        }
      )
      .then((e) => {
          if (e !== 'error') {
            const data = this.state.data
            data.find((o, i) => {
              if (o.id === key) {
                data.splice(i, 1)
                return true
              }
              return false
            })
            this.componentDidMount()
          }
        }
      )

  }

  update (e, _id, key) {
    window.location.href = `/admin/new-rubro?${_id}`
  }

  generateObject (data) {
    return {
      data: data.map((prop, key) => {
        return {
          id: key,
          name: prop.name,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a edit kind of action */}
              <Button
                onClick={(e) => this.update(e, prop._id, key)}
                bsStyle="warning"
                simple
                icon
              >
                <i className="fa fa-edit"/>
              </Button>{' '}
              {/* use this button to remove the data row */}
              <Button
                onClick={(e) => this.delete(e, prop._id, key)}
                bsStyle="danger"
                simple
                icon
              >
                <i className="fa fa-times"/>
              </Button>{' '}
            </div>
          )
        }
      })
    }
  }

  newRubro () {
    window.location.href = '/admin/new-rubro'
  }

  componentDidMount () {
    listRubros()
      .then((response) => response.json())
      .then(data => {
        this.updateState(data.data)
      })
  }

  render () {
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Rubros"
                content={
                  <>
                    <Button bsStyle="info" fill wd type="submit" onClick={this.newRubro}>Nuevo Rubro</Button>
                    <ReactTable
                      data={this.state.data}
                      filterable
                      columns={[
                        {
                          Header: 'Name',
                          accessor: 'name'
                        },
                        {
                          Header: 'Actions',
                          accessor: 'actions',
                          sortable: false,
                          filterable: false
                        }
                      ]}
                      defaultPageSize={10}
                      showPaginationTop
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    /></>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default RubrosTables
