import { API_ENDPOIND } from '../environments'

const POST_HEADER = { //eslint-disable-line
  'Content-Type': 'application/json',
  Authorization: `Bearer ${localStorage.getItem('token')}` // eslint-disable-line
}

const GET_HEADER = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}` // eslint-disable-line
  }
}

export const RubroSubmit = async (body) => {return fetch(`${API_ENDPOIND}/rubro`, { method: 'POST', headers: POST_HEADER, body: JSON.stringify(body) })}
export const listRubros = () => fetch(`${API_ENDPOIND}/rubro/list`, GET_HEADER)

/*delete*/
export const DeleteRubro = async (body) => {return fetch(`${API_ENDPOIND}/rubro`, { method: 'DELETE', headers: POST_HEADER, body: JSON.stringify(body) })}

export const getRubro = (id) => fetch(`${API_ENDPOIND}/rubro?id=${id}`, GET_HEADER)
