import routes from './routes'
import routes2 from './routes2'

let ruta = []
if (localStorage.getItem('permisos') && JSON.parse(localStorage.getItem('permisos')).permissions === 'superadmin') {
  ruta = routes
} else {
  ruta = routes2
}
console.log(ruta)
export default ruta
