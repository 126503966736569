import { API_ENDPOIND } from '../environments'

const POST_HEADER = { //eslint-disable-line
  'Content-Type': 'application/json',
  Authorization: `Bearer ${localStorage.getItem('token')}` // eslint-disable-line
}

const GET_HEADER = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}` // eslint-disable-line
  }
}

export const Submit = async (body) => {
  return fetch(`${API_ENDPOIND}/users`, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(body)
  })
}
export const List = () => fetch(`${API_ENDPOIND}/users/list`, GET_HEADER)
export const Delete = async (body) => {
  return fetch(`${API_ENDPOIND}/users`, {
    method: 'DELETE',
    headers: POST_HEADER,
    body: JSON.stringify(body)
  })
}
export const Get = (id) => fetch(`${API_ENDPOIND}/users?id=${id}`, GET_HEADER)
export const ChangePassword = (body) => fetch(`${API_ENDPOIND}/users/`, {
  method: 'PATCH',
  headers: POST_HEADER,
  body: JSON.stringify(body)
})
