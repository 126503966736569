import { API_ENDPOIND } from '../environments'

const POST_HEADER = { //eslint-disable-line
  'Content-Type': 'application/json',
  Authorization: `Bearer ${localStorage.getItem('token')}` // eslint-disable-line
}
const POST_HEADER_MULTIPART = { //eslint-disable-line
  // 'Content-Type': 'multipart/form-data',
  Authorization: `Bearer ${localStorage.getItem('token')}` // eslint-disable-line
}

const GET_HEADER = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json;',
    Authorization: `Bearer ${localStorage.getItem('token')}` // eslint-disable-line
  }
}

export const FileUpload = async (formData) => {
  return fetch(
    `${API_ENDPOIND}/image`,
    {
      method: 'POST',
      headers: POST_HEADER_MULTIPART
      , body: formData
    }
  )
}
export const Submit = async (body) => {return fetch(`${API_ENDPOIND}/brand`, { method: 'POST', headers: POST_HEADER, body: JSON.stringify(body) })}
export const List = () => fetch(`${API_ENDPOIND}/brand/list`, GET_HEADER)
export const Delete = async (body) => {
  return fetch(`${API_ENDPOIND}/brand`, {
    method: 'DELETE',
    headers: POST_HEADER,
    body: JSON.stringify(body)
  })
}
export const Get = (id) => fetch(`${API_ENDPOIND}/brand?id=${id}`, GET_HEADER)
