import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import AdminLayout from './layouts/Admin'
import AuthLayout from './layouts/Auth'
import { useDispatch, useSelector } from 'react-redux'

const App = () => {
  const dispatch = useDispatch()
  const redirectHomePage = (e) => {
    e.preventDefault()
    window.location = '/'
  }
  // const dispatch = useDispatch()
  // dispatch({ type: 'LOG_OUT' })
  const NoMatchPage = ({ location }) => {
    if (location.pathname === '/') {
      return <Redirect to='/admin/dashboard'/>
    }
    return (
      <div>
        <h3>404 - Not found</h3>
        <a href='/' onClick={redirectHomePage}> Redirect to Home Page</a>
      </div>
    )
  }
  const noMatch = () => {
    return <Redirect to='/auth/login-page'/>
  }
  const reducers = useSelector(state => state)
  if (reducers.logged === 'true') {
    return (<BrowserRouter>
      <Switch>
        <Route path="/admin" render={props => <AdminLayout {...props} />}/>
        <Route component={NoMatchPage} status={404}/>
        <Redirect from="/" to="/admin"/>
      </Switch>
    </BrowserRouter>)
  } else {
    return (
      <BrowserRouter>
        <Route exact path='/auth/login-page' component={AuthLayout}/>
        <Route exact path='/auth/register-page' component={AuthLayout}/>
        <Route component={noMatch} status={404}/>
        {/*<Route path="/auth/login-page" render={props => <AuthLayout {...props} />} />*/}
        {/*<Redirect to='/auth/login-page'/>*/}
        {/*<Redirect to='/auth/register-page'/>*/}
      </BrowserRouter>
    )
  }

}
export default App
