import { API_ENDPOIND } from '../environments'

export const signIn = (body) => fetch(`${API_ENDPOIND}/users/signin/`, { //eslint-disable-line
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(body)
})
export const register = (body) => fetch(`${API_ENDPOIND}/users/signup/`, { //eslint-disable-line
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(body)
})
