/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import { useDispatch } from 'react-redux'
import LoginForm from './LoginForm'
import swal from 'sweetalert2'
// import swal from 'swal-forms'
import { signIn } from '../../services/login'
import { ChangePassword } from '../../services/userServices'

const LoginPage = () => {
  const dispatch = useDispatch()
  const [state, setState] = React.useState({
    form: {
      email: '',
      password: '',
    },
  })
  const [cardHidden, setCardState] = React.useState({
    cardHidden: false
  })
  const handleSubmit = async (e) => {
    e.preventDefault()

    e.preventDefault()

    try {
      await signIn(state.form).then(function (response) {
        if (response.status === 200) {
          return response.json()
        } else {
          swal.fire('Datos Incorrectos',
            'El usuario o la contraseña son incorrectas',
            'error')
          return 'error'
        }
      }).then(function (data) {
        localStorage.setItem('token', data.data['accessToken']) //eslint-disable-line
        if (data.data.resetPassword && data.data.resetPassword === true) {
          swal.fire({
            title: 'Advertencia',
            text: 'Debe actualizar su contraseña',
            type: 'input',
            input: 'password',
            required: true,
            showCancelButton: true,
            closeOnConfirm: false,
            animation: 'slide-from-top'
          }).then((element) => {
            console.log(element)
            if (element.isConfirmed && element.value && element.value !== '') {
              console.log(`seteo ${element.value} como nueva contraseña e inicio sesion`)
              console.log(localStorage.getItem('token'))
              ChangePassword({ password: element.value })
                .then(r => {
                  if (r.status === 200) {
                    return r.json()
                  } else {
                    return 'Error'
                  }
                })
                .then(json => {
                  if (json === 'Error') {
                    swal.fire('Datos Incorrectos',
                      'El usuario o la contraseña son incorrectas',
                      'error')
                  } else {
                    localStorage.setItem('permisos', JSON.stringify(data.data['permisos'])) //eslint-disable-line
                    localStorage.setItem('user', 'true') //eslint-disable-line
                    dispatch({ type: 'LOG_IN' })
                    swal.fire('Bienvenido', 'Datos actualizados correctamente', 'success')
                      .then(() => {
                        if (data.data.permisos.permissions === 'superadmin') {
                          window.location = '/admin/marcas'
                        } else {
                          window.location = '/admin/locales'
                        }
                      })
                  }

                })
                .catch(() => swal.fire('Error', 'Error al actualizar la contraseña', 'error'))

            } else {
              swal.fire('Advertencia', 'la nueva contraseña no puede estar vacia', 'error')
            }
            if (element.isDismissed) {
              swal.fire('Advertencia', 'Debe actualizar su contraseña', 'error')
            }
          })

        } else {
          localStorage.setItem('permisos', JSON.stringify(data.data['permisos'])) //eslint-disable-line
          localStorage.setItem('user', 'true') //eslint-disable-line

          swal.fire('Bienvenido', '', 'success').then(function () {
            if (data.data.permisos.permissions === 'superadmin') {
              window.location = '/admin/marcas'
            } else {
              window.location = '/admin/locales'
            }
          })
        }

      })
    } catch (error) {
      console.error(`[singUp] ${error}`)
    }
  }

  const handleInputChange = (e) => {
    setState({
      form: {
        ...state.form,
        [e.target.name]: e.target.value,
      },
    })
  }
  return (
    <LoginForm
      handleInputChange={handleInputChange}
      cardHidden={cardHidden}
      handleSubmit={handleSubmit}
      form={state.form}
    />
  )
}

export default LoginPage
