import React, { Component, useEffect } from 'react'
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Form,
} from 'react-bootstrap'

import Card from 'components/Card/Card.jsx'

import Button from 'components/CustomButton/CustomButton.jsx'
import { getRubro, RubroSubmit } from '../../services/rubroServices'
import swal from 'sweetalert'

const RubroForm = (props) => {
  useEffect(() => {
      (async () => {
        const id = props.location.search.substring(1)
        if (id !== '') {}
        {
          try {
            getRubro(id)
              .then(e => (e.status === 200) ? e.json() : 'error')
              .then(data => {
                if (data !== 'error') {
                  console.log(data)
                  state.brand.id = data.result._id
                  state.brand.name = data.result.name
                    setState({
                      ...state,
                      ...state
                    })
                }
              })
              .catch(e => console.log(e))
          } catch (e) {
            console.log(e)
          }
        }
      })()
    },[]
  )

  const [state, setState] = React.useState({
    radio: '1',
    radioVariant: '1',
    brand: {
      id: '',
      name: '',
    }
  })

  const handleInputChange = (e) => {
    state.brand[e.target.name] = e.target.value
    setState({
      ...state,
      ...state
    })
    console.log(state)
  }

  const handleSubmit = e => {
    e.preventDefault()
    RubroSubmit(state.brand)
      .then(r => {
        if (r.status === 200) {
          swal('Guardado', '', 'success').then(r => window.location.href = '/admin/rubros')
        } else {
          swal('Ha Ocurrido un error durante el guardado', '', 'error').then(r => r)
        }
      })
      .catch()
    console.log(state)
    /*TODO service rubros save*/
  }
  const handleSelectChange = value => {
    this.setState({ multipleSelect: value })
  }

  console.log(state)
  return (
    <div className="main-content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={<legend>Rubro</legend>}
              content={
                <Form onSubmit={handleSubmit} horizontal>
                  <fieldset>
                    <FormGroup>
                      <ControlLabel className="col-sm-2">
                        Nombre
                      </ControlLabel>
                      <Col sm={10}>
                        <FormControl type="text" name='name' defaultValue={state.brand.name}
                                     onChange={handleInputChange}/>
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <FormGroup>
                    <Col md={9} mdOffset={5}>
                      <Button bsStyle="warning" fill wd onClick={e => window.location.href = '/admin/rubros'}>
                        Volver
                      </Button>
                      <Button bsStyle="default" fill wd type={'submit'}>
                        Guardar
                      </Button>
                    </Col>
                  </FormGroup>
                  {/*<FormGroup>*/}
                  {/*  <Col md={9} mdOffset={3}>*/}
                  {/*    <Button bsStyle="info" fill>*/}
                  {/*      Submit*/}
                  {/*    </Button>*/}
                  {/*  </Col>*/}
                  {/*</FormGroup>*/}
                </Form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default RubroForm
