import React, { useEffect } from 'react'
import { Col, ControlLabel, Form, FormControl, FormGroup, Grid, Row, } from 'react-bootstrap'

import Card from 'components/Card/Card.jsx'

import Button from 'components/CustomButton/CustomButton.jsx'
import { Get, Submit } from '../../services/userServices'
import swal from 'sweetalert'
import { ListBrands } from '../../services/localServices'
import Select from 'react-select'

const UserForm = (props) => {

  const [state, setState] = React.useState({
    radio: '1',
    radioVariant: '1',
    user: {
      id: '',
      name: '',
      email: '',
      selected_brand: null,
    },
    brands: [],
    count: 0
  })

  useEffect(() => {
      console.log(state.count)
      state.count++
      if (state.count <= 1) {
        (async () => {
          ListBrands()
            .then((response) => response.json())
            .then(data => {
              state.brands = [{
                value: '',
                label: '',
                isDisabled: true
              }, ...data.data.map(function (e) {

                return { value: e._id, label: e.name }
              })]
              const newState = JSON.parse(JSON.stringify(state))

              setState(newState)
            })
            .then(() => {
              const id = props.location.search.substring(1)
              console.log(id !== '')
              if (id !== '') {
                try {
                  Get(id)
                    .then(e => (e.status === 200) ? e.json() : 'error')
                    .then(data => {
                      if (data !== 'error') {
                        state.user.id = data.result._id
                        state.user.name = data.result.username
                        state.user.email = data.result.email
                        setState(state)
                      }
                    })
                    .catch(e => console.log(e))
                } catch (e) {
                  console.log(e)
                }
              }
            })

        })()
      }

    }, [state]
  )

  const handleInputChange = (e) => {
    state.user[e.target.name] = e.target.value
    setState({
      ...state,
      ...state
    })
    console.log(state)
  }

  const handleSubmit = e => {
    e.preventDefault()
    Submit(state.user)
      .then(r => {
        if (r.status === 200) {
          swal('Guardado', 'Operacion realizada satisfactoriamente', 'success').then(r => window.location.href = '/admin/user-tables')
        } else if (r.status === 409) {
          swal('Error', 'El email ya esta en uso', 'error').then(r => r)
        } else {
          swal('Error', 'Ha Ocurrido un error durante el guardado', 'error').then(r => r)
        }
      })
      .catch(e => e.json())
      .catch(e => console.log(e))
    console.log(state)
  }
  const handleSelectChange = value => {
    console.log(value)
    console.log(state)
    const newState = JSON.parse(JSON.stringify(state))
    newState.user.selected_brand = value
    setState(newState)
    console.log(state)
  }

  console.log(state)
  return (
    <div className="main-content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={<legend>Usuario</legend>}
              content={
                <Form onSubmit={handleSubmit} horizontal>
                  <fieldset>
                    <FormGroup>
                      <ControlLabel className="col-sm-2">
                        Nombre
                      </ControlLabel>
                      <Col sm={10}>
                        <FormControl type="text" name='name' defaultValue={state.user.name}
                                     onChange={handleInputChange} required/>
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <ControlLabel className="col-sm-2">
                        email
                      </ControlLabel>
                      <Col sm={10}>
                        <FormControl type="email" name='email' defaultValue={state.user.email}
                                     onChange={handleInputChange} required />
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <ControlLabel className="col-sm-2">
                        Marca
                      </ControlLabel>
                      <Col sm={10}>
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          placeholder="Marca"
                          name="select"
                          closeMenuOnSelect={true}
                          value={state.user.selected_brand}
                          onChange={handleSelectChange}
                          options={
                            state.brands
                          }
                          required
                        />
                      </Col>
                    </FormGroup>

                  </fieldset>
                  <FormGroup>
                    <Col md={9} mdOffset={5}>
                      <Button bsStyle="warning" fill wd onClick={e => window.location.href = '/admin/users'}>
                        Volver
                      </Button>
                      <Button bsStyle="default" fill wd type={'submit'}>
                        Guardar
                      </Button>
                    </Col>
                  </FormGroup>
                  {/*<FormGroup>*/}
                  {/*  <Col md={9} mdOffset={3}>*/}
                  {/*    <Button bsStyle="info" fill>*/}
                  {/*      Submit*/}
                  {/*    </Button>*/}
                  {/*  </Col>*/}
                  {/*</FormGroup>*/}
                </Form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default UserForm
